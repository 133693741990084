<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('withdraw.head_list3') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-divider class="mx-3" vertical />
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('withdraw.head_list4') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.member.phone" label="Username" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="New Password" outlined dense type="password" readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.member.line" label="Line" outlined dense readonly />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.member.member_game" label="Username (member)" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field label="Password (member)" outlined dense type="password" readonly />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('withdraw.head_list5') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-select v-model="datas.member.bank" :label="$t('bank')" item-text="label" :items="bankItems" outlined
                dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.member.bank_account" :label="$t('account_number')" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.member.name" :label="$t('account_name')" outlined dense readonly />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.amount" :label="$t('withdraw.amount')" outlined dense readonly />
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="datas.created_at" :label="$t('withdraw.header4')" outlined dense readonly />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'withdraw' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">{{ $t('back') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import store from '@/store'
export default {
  data() {
    return {
      breadcrumbsItems: [
        {
          text: this.$t('withdraw.head_list2'),
          disabled: false,
          href: '/withdraw',
        },
        {
          text: this.$t('withdraw.check_head'),
          disabled: true,
          href: '',
        },
      ],
      bankItems: [],
      underUse: [],
      id: this.$route.params.id,
      datas: {},
    }
  },
  async created() {
    this.addLogPage()
    this.bankItems = await this.$store.dispatch('getTypeBankData')
    this.data = await this.$store.dispatch('getWithdraw', {
      id: this.id,
      page: 1,
      rows: 1,
    })
    this.datas = this.data.data[0]
    this.datas.created_at = moment(this.datas.created_at).format('YY-MM-DD HH:mm')
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'ถอนเงิน',
            url: window.location.href,
            detail: 'ระบบจัดการถอนเงิน',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
  }
}
</script>